<template lang="pug">
	component(:is="mappedComponent", :nestingLevel="this.nestingLevel" :contents="this.contents", :key="`${this.contents.type}-${this.contents.id}`" :path="path")
</template>

<script>
import { props, faleConoscoComponents, faleConoscoDictionary } from '@/mixins/component'

export default {
    name: "section-content",
	props: {
		contents: Object,
		nestingLevel: String,
		path: Array,
	},
	components: {
		...faleConoscoComponents,
	},
	computed: {
		mappedComponent() {
			return this.$options.components[faleConoscoDictionary[this.contents.type]]
		},
	},
}
</script>

<style lang="stylus" scoped src="./Content.styl"></style>
